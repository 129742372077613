
import { Button } from "@mui/material";

function AboutSection() {
    return (
        <div className="container mx-auto flex flex-col sm:flex-col rounded shadow-lg mt-10 mb-14 md:flex-row p-8 md:flex-row bg-white p-8">
  
            <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mt-14">
                <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-center mt-4 sm:mt-0">About Us</h2>
                <p className="text-gray-700 text-center sm:text-left">
                    We are car workshop located in Elbasan offering car related services since 2015. 
                </p>
                
                <div className="mt-10 align-center text-center lg:px-20 mb-5">
                    <Button variant="outlined" 
                        href="#footer"
                        sx={{
                            backgroundColor: "#FCA311", 
                            width:'100%', 
                            color:"black", 
                            fontSize:"14px", 
                            fontWeight:"bold",
                            border: "none",
                            }}>
                            Contacts
                    </Button>
                </div>
            </div>
            
            <div className="w-full md:w-1/2 p-2">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1732.3612815292324!2d20.097505344456412!3d41.117475943478695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13504375637c279b%3A0xf5bd6ae248679e87!2s%22Auto%20Koli%22%20Rent%20A%20Car!5e0!3m2!1sen!2sit!4v1706921251092!5m2!1sen!2sit" 
                    width="100%"
                    height="500"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
      </div>
      

    )
}

export default AboutSection;
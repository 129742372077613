import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import  { Redirect } from 'react-router-dom'


export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const logout = (event) => {
    localStorage.removeItem("token");
    setTimeout (()=>{
      window.location.href='/'
    },50)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let isLoggedIn = localStorage.getItem("token") != null ? true : false;
  

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#1F2937', padding:"5px" }}>
        <Toolbar>
       
            <a href="/"><p className='pl-10 text-white text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl'><a>Rent a car Koli</a></p></a>

              <Typography
                variant="h4"
                component={Link}
                to="/"
                sx={{
                  flexGrow: 1,
                  
                }}
              >
              
              </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: 'white' }} 
            onClick={handleClick}
          >
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >

<div>
            {!isLoggedIn ? (
             <div>
                <MenuItem component={Link} to="/login" >
                  Login
                </MenuItem>
              </div>
            ) : (
              <div>
                <MenuItem onClick={logout} >
                  Logout
                </MenuItem>
              </div>
            )}
          </div>

            <div>
            {isLoggedIn ? (
             <div>
                  <MenuItem component={Link} to="/admin" >
                  Admin Panel
                </MenuItem>
                <MenuItem component={Link} to="/addcar" >
                  Add New Car
                </MenuItem>
                <MenuItem component={Link} to="/userbookings" >
                  Bookings
                </MenuItem>
                <MenuItem component={Link} to="/register" >
                  Signup
                </MenuItem>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import carImage from '../assets/img/g-amg.png';
import BlakeMeyer from '../assets/img/blake-meyer.jpg';
import JoeyBanks from '../assets/img/joey-banks.jpg';
import BroomField from '../assets/img/peter-broomfield.jpg';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import CustomButton from './ui/CustomButton';

function CarouselComponent() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={2000} >
      <Carousel.Item>
        <img src={BlakeMeyer} 
          text="First slide"  
          style={{ minHeight: '50vh', maxHeight: '90vh' , width: '100%', objectFit: 'cover'}}/>
          
        <Carousel.Caption>
          <a href="#footer"><CustomButton content="CONTACT US" icon={<LaunchIcon />} /></a>
           
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={JoeyBanks} text="First slide"  
      style={{ minHeight: '50vh',  maxHeight: '90vh' , width: '100%', objectFit: 'cover'}}/>
       
        <Carousel.Caption>
        <a href="#footer"><CustomButton content="CONTACT US" icon={<LaunchIcon />} /></a>
        </Carousel.Caption>

      </Carousel.Item>
      <Carousel.Item>

      <img src={BroomField} text="First slide"  
        style={{ minHeight: '50vh',  maxHeight: '90vh' , width: '100%', objectFit: 'cover'}}/>

        <Carousel.Caption>
        <a href="#footer"><CustomButton content="CONTACT US" icon={<LaunchIcon />} /></a>
        </Carousel.Caption>

      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;
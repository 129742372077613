import React from 'react';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

import { styled } from '@mui/material/styles';


// Define a custom styled button
const StyledButton = styled(Button)(({ theme }) => ({
  size: 'large',
  color: 'black',
  fontWeight: 'bold',
  fontSize: '18px',
  backgroundColor: '#FCA311',
  paddingInline: '20px',
  borderRadius: '20px',
  border: '2px solid transparent',
  transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#FCA311',
    borderColor: '#FCA311',
  },
  [theme.breakpoints.down('sm')]: {
    paddingInline: '10px',
    fontSize: '16px',
  },
}));

const CustomButton = ({ content, icon = null }) => {
  return (
    <StyledButton>
      <p className='text-sm sm:text-sm md:text-lg lg:text-lg'>

        {content} 
      </p>
      {icon && <span style={{ marginLeft: '5px' }}>{icon}</span>}
    </StyledButton>
  );
};

export default CustomButton;

import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import IntroLayout from '../components/Intro';
import {useDispatch, useSelector} from 'react-redux'
import { getAllCars,getAvailableCars } from '../redux/actions/carsActions';
import { Col, DatePicker, Row ,Card,message} from 'antd';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

import CarouselComponent from '../components/Carousel';
import Slider from '../components/ui/Slider';
import carImg from '../assets/img/g-amg.png';
import carImg2 from '../assets/img/joey-banks.jpg';
import AboutSection from '../components/About';

import moment from 'moment';

const {Meta} = Card;

message.config(
    {
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
      }
)

function BookingCompleted(){
    const [errorMessage, setErrorMessage] = useState(""); // Changed React.useState to useState
    useEffect(() => {

    }, []);

    useEffect(()=>{
        console.log("error message:");
        console.log(errorMessage);
    },[])


    return(
        <div>
            <div className="container mx-auto flex flex-col sm:flex-col rounded shadow-lg mt-24 mb-14 md:flex-row bg-white px-8 py-14">
            <p className="text-gray-700 text-center sm:text-center" style={{fontSize:"3em",alignItems:'center',paddingLeft:'100px',paddingRight:'100px'}}>
                     The transaction was <b>successful</b>. You will receive an email with a confirmation, if not received please contact us. Thank you!
                    </p>
                    </div>
                    <p className="text-gray-700 text-center sm:text-center" style={{color:'green',fontSize:"4em",alignItems:'center',paddingLeft:'100px',paddingRight:'100px'}}>
                     Booking completed!
                    </p>
            
        
        </div>
  
    )
}

export default BookingCompleted;
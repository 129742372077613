import logo from './logo.svg';
// import './App.css';
import './DateRangePicker.css';
import {Route , BrowserRouter, Routes, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import BookingCar from './pages/BookingCar';
import UserBookings from './pages/UserBookings';
import BookingCompleted from './pages/BookingCompleted';
import AddCar from './pages/AddCar';
import AdminHome from './pages/AdminHome';
import EditCar from './pages/EditCar';
import Header from './components/Header';
import Footer from './components/Footer';
import './index.css';

function App() {
  const force_https = false;//to be enabled
  if (document.location.protocol !== 'https:' && force_https ) {
    document.location.replace(`https:${document.location.href.substring(document.location.protocol.length)}`);
  }
  return (
    <div>
      <BrowserRouter>
        <Header/>
      <Routes>
      <Route path="/" exact element={<Home/>}/>
        <Route path="/login" exact element={<Login/>}/>
        <Route path="/register" exact element={<ProtectedRoute ><Register/></ProtectedRoute>}/>
        <Route path="/bookingcompleted" exact element={<BookingCompleted/>}/>
        <Route path="/booking/:carid" exact element={<BookingCar/>}/>
        <Route path="/userbookings" exact element={<ProtectedRoute ><UserBookings/></ProtectedRoute>}/>
        <Route path="/addcar" exact element={<ProtectedRoute ><AddCar/></ProtectedRoute>}/>
        <Route path="/admin" exact element={<ProtectedRoute ><AdminHome/></ProtectedRoute>}/>
        <Route path="/editcar/:carid" exact element={<ProtectedRoute ><EditCar/></ProtectedRoute>}/>
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

export function ProtectedRoute ({children})
{
      if(localStorage.getItem('token'))
      {
        //Supposed to authenticate
        return children
      }
      else{
        return <Navigate to='/login'/>
      }
}

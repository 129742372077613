import { message } from 'antd';
import axios from 'axios';
let pckg = require("../../../package.json");

let auth_token=localStorage.getItem("token");
if (typeof auth_token != "undefined"){
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth_token}`;
}


export const getAllCars=()=>async dispatch=>{

    dispatch({type: 'LOADING', payload:true})
        let contentful_data;
        try {
        
        const response = await axios.get(`${pckg.proxy}/api/cars/getallcars`)
        dispatch({type: 'GET_ALL_CARS', payload:response.data})
        dispatch({type: 'LOADING', payload:false})
        
        } catch (error){
            console.log(error);
            dispatch({type: 'LOADING', payload:false})
        }



    }
    
    export const getAvailableCars=(reqObj)=>async dispatch=>{
        dispatch({type: 'LOADING', payload:true})
        try{
            const response = await axios.post(`${pckg.proxy}/api/cars/getavailablecars`,reqObj)
            console.log("available cars:");
            console.log(response);
            dispatch({type: 'GET_AVAILABLE_CARS', payload:response.data})
        }catch(e){
            console.log("An error occurred getting available cars!")
        }
        dispatch({type: 'LOADING', payload:false})
    }
    
 
    export const addCar=(reqObj)=>async dispatch=>{

        dispatch({type: 'LOADING', payload:true})
            try {
                
                if (typeof auth_token == "undefined"){
                    throw Error("NoToken");
                }
                const response = await axios.post(`${pckg.proxy}/api/cars/addcar`,reqObj)
                console.log(response);
                dispatch({type: 'LOADING', payload:false})
                message.success('New car added successfully')
                setTimeout(()=>{
                    window.location.href='/admin'
                },200)
                
            } catch (error){
                console.log(error);
                dispatch({type: 'LOADING', payload:false})
                if(error=="NoToken"){
                    message.warning("Session expired, please login again.");
                    setTimeout(()=>{
                        window.location.href='/login'
                    },200)
                }
            }
    
    }

    export const editCar=(reqObj)=>async dispatch=>{

        dispatch({type: 'LOADING', payload:true})
            try {

                
                if (typeof auth_token == "undefined"){
                    throw Error("NoToken");
                }
                let config = {
                    headers: {
                        Authorization: auth_token,
                    }
                }

                const response = await axios.post(`${pckg.proxy}/api/cars/editcar`,reqObj)
                
                dispatch({type: 'LOADING', payload:false})
                message.success('Car details updated successfully')
                setTimeout(()=>{
                    window.location.href='/admin'
                },200)
            
            } catch (error){
                console.log(error);
                dispatch({type: 'LOADING', payload:false})
            }
    
    }

    export const deleteCar=(reqObj)=>async dispatch=>{

        dispatch({type: 'LOADING', payload:true})
            try {
                
                if (typeof auth_token == "undefined"){
                    throw Error("NoToken");
                }

                const response = await axios.post(`${pckg.proxy}/api/cars/deletecar`,reqObj);
                console.log(response);
                
                dispatch({type: 'LOADING', payload:false})
                message.success('Car deleted successfully')
                setTimeout(()=>{
                    window.location.reload()
                },200)
                
            } catch (error){
                if(error == "NoToken"){
                    setTimeout (()=>{
                        window.location.href='/login'
                    },50)
                }
                console.log(error);
                dispatch({type: 'LOADING', payload:false})
            }
    
    }

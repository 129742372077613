import React, { useState, useEffect } from 'react';


import Carousel from 'react-bootstrap/Carousel';


const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/">
          Profile
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/userbookings">
          My bookings
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/" onClick={(()=>{
            localStorage.removeItem('user');
            window.location.href="/login";
        })}>
          Logout
        </a>
      ),
    },
  ];

  let user= JSON.parse(localStorage.getItem('user'));
  user = {
    username : "Login"
  }
  const video_path = './main_movie.mp4'


  const images = [
    {
       src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
       width: 320,
       height: 174,
       caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
       src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
       width: 320,
       height: 212,
       tags: [
          { value: "Ocean", title: "Ocean" },
          { value: "People", title: "People" },
       ],
       alt: "Boats (Jeshu John - designerspics.com)",
    },
    {
       src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
       width: 320,
       height: 212,
    },
 ];

 const card_styles = [
  'Primary',
  'Secondary',
  'Success',
  'Danger',
  'Warning',
  'Info',
  'Light',
  'Dark',
]
  
function DefaultLayout(props) {

    return ( 
      <div>
              <div style={{paddingTop:"100px"}}>

              </div>
            <div className='header'>
    
                <div id="cars" className='content'>
                    {props.children}
                </div>
            </div>
        </div>
     )
}

export default DefaultLayout;
import { Typography } from '@mui/material';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function CardComponent({cars}) {
 
  return (
    <ul role="list" className="container grid  gap-6 sm:grid-cols-2 mx-3 md:grid-cols-3 lg:grid-cols-4 my-5 sm:my-7 md:my-14 lg:my-14 xl:my-14">
      {cars.map((car, index) => (
        <li
        key={car._id}
        className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
      >
        <div className="flex flex-1 flex-col items-start">
          <img className="mx-auto h-48 sm:h-48 md:h-72 lg:h-72 xl:h-72 w-full flex-shrink-0 rounded-xl" src={car.image} alt="" />
          
          <div className='pl-5 mb-3'>

            <h3 className="mt-6 text-base font-semibold text-left capitalize sm:text-base md:text-base lg:text-lg xl:text-xl">{car.name}</h3>
            <p className='text-left capitalize text-sm mt-2 sm:text-sm md:text-base lg:text-base xl:text-lg'>Capacity: {car.capacity}</p>
            <p className='text-left capitalize text-sm mt-2 sm:text-sm md:text-base lg:text-base xl:text-lg'>Fuel Type: {car.fuelType}</p>
            <p className='text-left capitalize text-sm sm:text-base md:text-base lg:text-base xl:text-lg'>Daily rent price:  <span className='ml-2 italic'>{car.rentPerHour} €</span> </p>
          </div>
              
           
        </div>
        <div>
          <div className="-mt-px flex ">
            <div className="flex w-0 flex-1 ">
              
                  <Link to={`/booking/${car._id}`} className='relative bg-[#FCA311] inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-lg border border-transparent py-2  text-base sm:text-base md:text-lg lg:text-xl tracking-wider font-semibold text-black sm:w-auto'>
                  Book Now
                  </Link>
            </div>
            
          </div>
        </div>
      </li>
    ))}
  
</ul>
  
   
  );
}

export default CardComponent;
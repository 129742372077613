import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Space } from 'antd';

const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="/">
        Profile
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="/userbookings">
        My bookings
      </a>
    ),
  },
  {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="/" onClick={(()=>{
          localStorage.removeItem('user');
          window.location.href="/login";
      })}>
        Logout
      </a>
    ),
  },
];


  let user= JSON.parse(localStorage.getItem('user'));
  user = {
    username : "Login"
  }
  const video_path = './main_movie.mp4'

  
function IntroLayout(props) {

    return ( 
      <div>
              <video class="bg_video" id="video" autoPlay muted>
                <source src={video_path} type='video/mp4' />
              </video>
            <div className='header'>
                <div className='d-flex justify-content-between border' style={{
                    padding:10,
                }}>
                    <h1 id="logo">Koli Rental</h1>
                    <Space direction="vertical" style={{
                        marginRight:30
                    }}>
                      
    <Space wrap>
      
      
      <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
      >
        <Button>{user.username}</Button>
      </Dropdown>
      
    </Space>
    
  </Space>
  
                </div>
            </div>
        </div>
     )
}

export default IntroLayout;
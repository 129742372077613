import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../assets/css/styles.css";
import Card from './Card';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Slider = ({cars}) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="">
      <div className="mt-14 flex justify-end">

        <Button
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: 'black' , marginBottom:"18px", backgroundColor:"#FCA311" }} 
            onClick={handleClick}
          >
            <p className="text-sm sm:text-sm md:text-base lg:text-base capitalize font-bold"> Order by <KeyboardArrowDownIcon /> </p>
           
        </Button>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem >
              Lowest price first
            </MenuItem>
            <MenuItem >
              Highest  price first
            </MenuItem>
          </Menu>
      </div>
      {
        cars.length > 0 ? (
            <Card
            cars={cars}
          />
        ):(null)
      }
       
  
    </div>
  );
};
export default Slider;

import { message } from "antd";
import axios from "axios";
let pckg = require("../../../package.json");

export const userLogin = (reqobj) => async dispatch => {
    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(`${pckg.proxy}/api/users/login`, reqobj)
        localStorage.setItem('token', `${response.data}`)
        dispatch({ type: 'LOADING', payload: false })
        message.success('login successfull')
        setTimeout (()=>{
            window.location.href='/'
        },50)
    } catch (error) {
        message.error("Wrong credentials");
        dispatch({ type: 'LOADING', payload: false })
    }
}
export const userLogout = (reqobj) => async dispatch => {
    dispatch({ type: 'LOADING', payload: true })
    try {
        localStorage.removeItem("token");
        dispatch({ type: 'LOADING', payload: false })
        message.success('Logged out')
        
    } catch (error) {
        message.error("Wrong credentials");
        dispatch({ type: 'LOADING', payload: false })
    }
}

export const userRegister = (reqobj) => async dispatch=> {
    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post(`${pckg.proxy}/api/users/register`, reqobj)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Registration successfull')
        setTimeout (()=>{
            window.location.href='/login'
        },50)
} catch (error) {
        {
            message.error('Something went wrong')
            dispatch({ type: 'LOADING', payload: false })
        }
    }
}